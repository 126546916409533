<template>
  <router-view :key="$route.fullPath" />
</template>

<script>
import PrPreLoaderFull from "@/components/custom/PrPreLoaderFull.vue";
import { setThemeDark, setThemeLight } from "@/utils/theme";
import { checkAuth } from "@/utils/checkAuth";

import setLanguage from "@/utils/language";

export default {
  components: {
    PrPreLoaderFull,
  },
  data() {
    return {
      showPreLoad: true,
      show: false,
    };
  },
  methods: {
    sleep: function (time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },

    setNeedJs() {
      this.sleep(1500).then(() => {
        HSCore.components.HSDatatables.init(".js-datatable");
        HSCore.components.HSTomSelect.init(".js-select");
        HSCore.components.HSQuill.init(".js-quill");

        const exportDatatable =
          HSCore.components.HSDatatables.getItem("exportDatatable");

        document
          .getElementById("export-copy")
          .addEventListener("click", function () {
            exportDatatable.button(".buttons-copy").trigger();
          });

        document
          .getElementById("export-excel")
          .addEventListener("click", function () {
            exportDatatable.button(".buttons-excel").trigger();
          });

        document
          .getElementById("export-csv")
          .addEventListener("click", function () {
            exportDatatable.button(".buttons-csv").trigger();
          });

        document
          .getElementById("export-pdf")
          .addEventListener("click", function () {
            exportDatatable.button(".buttons-pdf").trigger();
          });

        document
          .getElementById("export-print")
          .addEventListener("click", function () {
            exportDatatable.button(".buttons-print").trigger();
          });
      });
    },
  },

  watch: {},
  async created() {
    this.$store.commit("readDashboardLanguage");
    if (this.$store.state.dashboardLanguage === "en") {
      setLanguage("en");
    } else {
      setLanguage("fa");
    }

    this.$store.commit("readDashboardTheme");
    if (this.$store.state.dashboardTheme === "dark") {
      setThemeDark();
    }

    //  await this.checkAreaAccess();
  },

  mounted() {
    //this.setThemeDark();
    // this.setNeedJs()
  },
};
</script>

<style>
/**
  * Persian Number: Vazirmatn FD
  * Persian Number: Noto Sans Arabic
  * English Number: Vazirmatn
  */
.invalid-feedback {
  display: unset !important;
}

.list-checked-item::before {
  background-image: unset !important;
}

html {
  -moz-transition: left 1s ease 0.1s, width 0s ease-out 0.1s,
    border-width 0s ease-out 0.1s;
  -webkit-transition: left 1s ease 0.1s, width 0s ease-out 0.1s,
    border-width 0s ease-out 0.1s;
}

body {
  font-family: "Vazirmatn", sans-serif;
}

#app {
  font-family: "Vazirmatn", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.nav-link dropdown-toggle.router-link-exact-active {
  color: red;
}

.nav-link dropdown-toggle.router-link-exact-active:hover {
  color: green;
}

p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
td,
tr,
code,
strong,
small,
span,
button,
label {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.select2-container--default {
  display: block;
  width: 100%;
  padding: 0.6125rem 2.25rem 0.6125rem 1rem;
  -moz-padding-start: calc(1rem - 3px);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #677788;
  background-color: #fff;

  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 13px 9px;
  border: 0.0625rem solid rgba(231, 234, 243, 0.7);
  border-radius: 0.3125rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.select2-container--default .select2-selection--single {
  border: unset;
}

.select2-search__field {
  color: #1e2022;
  border: 0.0625rem solid rgba(231, 234, 243, 0.7) !important;
  border-radius: 0.3125rem;
}

.select2-dropdown select2-dropdown--below {
  background-color: #fff;
  border: 0.0625rem solid rgba(231, 234, 243, 0.7);
  border-radius: 0.3125rem;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
}

.nav .nav-tabs li {
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d1cece;
}

.vpd-input-group input {
  line-height: 1.5 !important;
  padding: 0.6125rem 1rem !important;
}

.Vue-Toastification__toast-body {
  font-family: "Noto Sans Arabic";
  direction: rtl;
  text-align: right;
  margin-left: 7px;
  margin-right: 7px;
}
.Vue-Toastification__toast--warning {
  color: var(--bs-gray-900) !important;
}
</style>

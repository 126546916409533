export const routes = (parentName) => {
    return [
        {
            path: 'organizations',
            name: `${parentName}-Organizations-Index`,
            component: () => import('./Index.vue'),
            redirect: {
                name: `${parentName}-Organizations-Organizations-List`
            },
            children: [
                {
                    path: 'organization',
                    name: `${parentName}-Organizations-Organizations`,
                    component: () => import('./oraganization/Index.vue'),
                    redirect: {
                        name: `${parentName}-Organizations-Organizations-List`
                    },
                    children: [
                        {
                            path: '',
                            name: `${parentName}-Organizations-Organizations-List`,
                            component: () => import('./oraganization/List.vue')
                        },
                        {
                            path: 'new',
                            name: `${parentName}-Organizations-Organizations-New`,
                            component: () => import('./oraganization/Upsert.vue')
                        },
                        {
                            path: ':id/edit',
                            path: `${parentName}-Organizations-Organizations-Edit`,
                            component: () => import('./oraganization/Upsert.vue')
                        }
                    ]
                },
                {
                    path: 'company',
                    name: `${parentName}-Organizations-Company`,
                    component: () => import('./company/Index.vue'),
                    redirect: {
                        name: `${parentName}-Organizations-Company-List`
                    },
                    children: [
                        {
                            path: '',
                            name: `${parentName}-Organizations-Company-List`,
                            component: () => import('./company/List.vue')
                        },
                        {
                            path: 'new',
                            name: `${parentName}-Organizations-Company-New`,
                            component: () => import('./company/Upsert.vue')
                        }
                    ]
                },
                {
                    path: 'unit',
                    name: `${parentName}-Organizations-unit`,
                    component: () => import('./unit/Index.vue'),
                    redirect: {
                        name: `${parentName}-Organizations-unit-List`
                    },
                    children: [
                        {
                            path: '',
                            name: `${parentName}-Organizations-unit-List`,
                            component: () => import('./unit/List.vue')
                        },
                        {
                            path: 'new',
                            name: `${parentName}-Organizations-unit-New`,
                            component: () => import('./unit/Upsert.vue')
                        }
                    ]
                }
            ]
        },
    ]
}
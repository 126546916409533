import axios from 'axios';
import { createStore } from 'vuex';

export default createStore({
  state: {
    // ------- ------- //
    managerIsAuthenticated: false,
    managerToken: '',
    managerTokenStorageKeyName: 'psiketManagerToken',
    managerRoutesGroupName: 'manager',
    managerRoutesPathRoot: '/manager/',
    managerRoutesParentName: 'PanelManager',
    // ------- ------- //

    // ------- ------- //
    operatorIsAuthenticated: false,
    operatorToken: '',
    operatorTokenStorageKeyName: 'psiketOperatorToken',
    operatorRoutesGroupName: 'operator',
    operatorRoutesPathRoot: '/operator/',
    operatorRoutesParentName: 'PanelOperator',
    // ------- ------- //

    // ------- ------- //
    userDetails: {
      firstName: '',
      lastName: '',
    },
    userIsAuthenticated: false,
    userToken: '',
    userTokenStorageKeyName: 'psiketUserToken',
    userRoutesGroupName: 'user',
    userRoutesPathRoot: '/user/',
    userRoutesParentName: 'PanelUser',
    // ------- ------- //

    // ------- API base url ------- //
    apiBaseUrlKeyName: 'psiketApiBaseUrl',
    apiBaseUrl: '',
    // ------- ------- //

    dashboardThemeKeyName: 'crmDashboardTheme',
    dashboardTheme: 'light',

    dashboardLanguageKeyName: 'crmDashboardLanguage',
    dashboardLanguage: 'fa',

    dashboardDirectionKeyName: 'crmDashboardDirection',
    dashboardDirection: 'rtl',
  },
  getters: {},
  mutations: {
    // ------- API base url     ------- ------- ------- ------- ------- ------- ------- //
    readApiBaseUrl(state) {
      let apiBaseUrl = localStorage.getItem(state.apiBaseUrlKeyName);
      if (apiBaseUrl) {
        state.apiBaseUrl = apiBaseUrl;
      } else {
        state.apiBaseUrl = 'localhost';
      }
    },
    setApiBaseUrl(state, apiBaseUrl) {
      state.apiBaseUrl = apiBaseUrl;
      localStorage.setItem(state.apiBaseUrlKeyName, state.apiBaseUrl);
    },
    // ------- END API base url ------- ------- ------- ------- ------- ------- ------- //

    // ------- Theme ------- ------- ------- ------- ------- ------- ------- //
    readDashboardTheme(state) {
      let dashboardTheme = localStorage.getItem(state.dashboardThemeKeyName);
      if (dashboardTheme === 'dark') {
        state.dashboardTheme = 'dark';
      } else if (dashboardTheme === 'light') {
        state.dashboardTheme = 'light';
      } else {
        state.dashboardTheme = 'light';
        localStorage.setItem(state.dashboardThemeKeyName, 'light'); //مهم
      }
    },

    setDashboardTheme(state, dashboardTheme) {
      if (dashboardTheme === 'dark') {
        state.dashboardTheme = 'dark';
        localStorage.setItem(state.dashboardThemeKeyName, state.dashboardTheme);
      } else {
        state.dashboardTheme = 'light';
        localStorage.setItem(state.dashboardThemeKeyName, state.dashboardTheme);
      }
    },

    // ------- END Theme ------- ------- ------- ------- ------- ------- ------- //

    // ------- Language ------- ------- ------- ------- ------- ------- ------- //
    readDashboardLanguage(state) {
      let dashboardLanguage = localStorage.getItem(state.dashboardLanguageKeyName);
      if (dashboardLanguage === 'fa') {
        state.dashboardLanguage = 'fa';
      } else if (dashboardLanguage === 'en') {
        state.dashboardLanguage = 'en';
      } else {
        state.dashboardLanguage = 'fa';
        localStorage.setItem(state.dashboardLanguageKeyName, 'fa'); //مهم
      }
    },

    setDashboardLanguage(state, dashboardLanguage) {
      if (dashboardLanguage === 'en') {
        state.dashboardLanguage = 'en';
        localStorage.setItem(state.dashboardLanguageKeyName, state.dashboardLanguage);
      } else {
        state.dashboardLanguage = 'fa';
        localStorage.setItem(state.dashboardLanguageKeyName, state.dashboardLanguage);
      }
    },

    // ------- END Language ------- ------- ------- ------- ------- ------- ------- //

    // ------- Direction ------- ------- ------- ------- ------- ------- ------- //
    readDashboardDirection(state) {
      let dashboardLanguage = localStorage.getItem(state.dashboardLanguageKeyName);
      if (dashboardLanguage === 'fa') {
        state.dashboardLanguage = 'fa';
      } else if (dashboardLanguage === 'en') {
        state.dashboardLanguage = 'en';
      } else {
        state.dashboardLanguage = 'fa';
        localStorage.setItem(state.dashboardLanguageKeyName, 'fa'); //مهم
      }
    },

    setDashboardDirection(state, dashboardDirection) {
      if (dashboardDirection === 'ltr') {
        state.dashboardDirection = 'ltr';
        localStorage.setItem(state.dashboardDirectionKeyName, state.dashboardDirection);
      } else {
        state.dashboardDirection = 'rtl';
        localStorage.setItem(state.dashboardDirectionKeyName, state.dashboardDirection);
      }
    },

    // ------- END Direction ------- ------- ------- ------- ------- ------- ------- //

    // +++++++ MANAGER STORE +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ //
    readManagerToken(state) {
      let managerToken = localStorage.getItem(state.managerTokenStorageKeyName);
      state.managerToken = managerToken;
      /*
      if (managerToken) {
        state.managerIsAuthenticated = true;
        state.managerToken = managerToken;
        axios.defaults.headers.common["Authorization"] =
          "Token " + managerToken;
      } else {
        state.managerIsAuthenticated = false;
        state.managerToken = "";
        axios.defaults.headers.common["Authorization"] = "";
      }
      */
    },

    setManagerToken(state, managerToken) {
      if (managerToken) {
        state.managerIsAuthenticated = true;
        state.managerToken = managerToken;
        localStorage.setItem(state.managerTokenStorageKeyName, managerToken);
        // axios.defaults.headers.common['x-token-manager'] = 'Bearer ' + managerToken;
        axios.defaults.headers.common['x-token-manager'] = managerToken;
      } else {
        state.managerIsAuthenticated = false;
        state.managerToken = '';
        localStorage.removeItem(state.managerTokenStorageKeyName);
        axios.defaults.headers.common['x-token-manager'] = '';
      }
    },

    clearManagerToken(state) {
      state.managerIsAuthenticated = false;
      state.managerToken = '';
      localStorage.removeItem(state.managerTokenStorageKeyName);
      axios.defaults.headers.common['x-token-manager'] = '';
    },
    // ####### END MANAGER STORE ####### ####### ####### ####### ####### ####### ####### //

    // +++++++ USER STORE +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ //
    readUserToken(state) {
      let token = localStorage.getItem(state.userTokenStorageKeyName);
      state.userToken = token;
    },

    setUserToken(state, userToken) {
      if (userToken) {
        state.userIsAuthenticated = true;
        state.userToken = userToken;
        localStorage.setItem(state.userTokenStorageKeyName, userToken);
        axios.defaults.headers.common['x-token-user'] = userToken;
      } else {
        state.userIsAuthenticated = false;
        state.userToken = '';
        localStorage.removeItem(state.userTokenStorageKeyName);
        axios.defaults.headers.common['x-token-user'] = '';
      }
    },

    clearUserToken(state) {
      state.userIsAuthenticated = false;
      state.userToken = '';
      localStorage.removeItem(state.userTokenStorageKeyName);
      axios.defaults.headers.common['x-token-user'] = '';
    },
    setUserDetails(state, userDetails) {
      state.userDetails.firstName = userDetails.firstName;
      state.userDetails.lastName = userDetails.lastName;
    },
    // ####### END USER STORE ####### ####### ####### ####### ####### ####### ####### //

    // +++++++ OPERATOR STORE +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ +++++++ //
    readOperatorToken(state) {
      let operatorToken = localStorage.getItem(state.operatorTokenStorageKeyName);
      state.operatorToken = operatorToken;
    },

    setOperatorToken(state, operatorToken) {
      if (operatorToken) {
        state.operatorIsAuthenticated = true;
        state.operatorToken = operatorToken;
        localStorage.setItem(state.operatorTokenStorageKeyName, operatorToken);
        // axios.defaults.headers.common['x-token-operator'] = 'Bearer ' + operatorToken;
        axios.defaults.headers.common['x-token-operator'] = operatorToken;
      } else {
        state.operatorIsAuthenticated = false;
        state.operatorToken = '';
        localStorage.removeItem(state.operatorTokenStorageKeyName);
        axios.defaults.headers.common['x-token-operator'] = '';
      }
    },

    clearOperatorToken(state) {
      state.operatorIsAuthenticated = false;
      state.operatorToken = '';
      localStorage.removeItem(state.operatorTokenStorageKeyName);
      axios.defaults.headers.common['x-token-operator'] = '';
    },
    // ####### END MANAGER STORE ####### ####### ####### ####### ####### ####### ####### //
  },
  actions: {
    /** ------- MANAGER ------- ------- ------- ------- ------- ------- ------- */
    execReadManagerToken({ commit, state }) {
      commit('readManagerToken');
    },

    execSetManagerToken({ commit, state }) {
      commit('setManagerToken');
    },

    execClearManagerToken({ commit }) {
      commit('clearManagerToken');
    },

    /** ------- END MANAGER ------- ------- ------- ------- ------- ------- ------- */

    /** ------- USER ------- ------- ------- ------- ------- ------- ------- */
    execReadUserToken({ commit, state }) {
      commit('readUserToken');
    },

    execSetUserToken({ commit, state }) {
      commit('setUserToken');
    },

    execClearUserToken({ commit }) {
      commit('clearUserToken');
    },

    /** ------- END USER ------- ------- ------- ------- ------- ------- ------- */

    /** ------- OPERATOR ------- ------- ------- ------- ------- ------- ------- */
    execReadOperatorToken({ commit, state }) {
      commit('readOperatorToken');
    },

    execSetOperatorToken({ commit, state }) {
      commit('setOperatorToken');
    },

    execClearOperatorToken({ commit }) {
      commit('clearOperatorrToken');
    },

    /** ------- END OPERATOR ------- ------- ------- ------- ------- ------- ------- */

    //
    execReadDashboardTheme({ commit }) {
      commit('readDashboardTheme');
    },

    execSetDashboardTheme({ commit }) {
      commit('setDashboardTheme');
    },
    //
  },
  modules: {},
});

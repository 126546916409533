<template>
  <div
    id="header"
    class="
      navbar
      navbar-expand-lg
      navbar-fixed
      navbar-height
      navbar-container
      navbar-bordered
      bg-white
    "
  >
    <div class="navbar-nav-wrap">
      <!-- Logo -->

      <!-- End Logo -->

      <div class="navbar-nav-wrap-content-start">
        <!-- Navbar Vertical Toggle -->
        <i
          v-if="showMenu"
          class="bi bi-list text-dark"
          style="font-size: 21px; transition: all 0.5s"
          :style="[componentValue ? { transform: 'rotate(90deg)' } : {}]"
          @click="change()"
        ></i>

        <span class="text-dark">
          <i v-if="nowDataTime" class="bi bi-calendar mx-2"></i>
          {{ nowDataTime }}
          <audio
            id="alert"
            src="/assets/mp3/beep-alert-2.mp3"
            preload="auto"
            style="display: none"
          ></audio>

          <audio
            id="event"
            src="/assets/mp3/beep.mp3"
            preload="auto"
            style="display: none"
          ></audio>
        </span>

        <div
          v-if="trrigerEvent"
          class="alert alert-danger blink mt-2 mx-2"
          role="alert"
        >
          <i class="bi bi-exclamation-triangle mx-2"></i>
          {{ $t("event") }}
        </div>

        <div
          v-if="trrigerAlert"
          class="alert alert-danger blink mt-2 mx-2"
          role="alert"
        >
          <i class="bi bi-exclamation-triangle mx-2"></i>
          {{ $t("alert") }}
        </div>
        <!-- End Navbar Vertical Toggle -->

        <!-- Search Form -->
        <div class="dropdown ms-2">
          <!-- End Input Group -->

          <!-- Card Search Content -->
          <div
            id="searchDropdownMenu"
            class="
              hs-form-search-menu-content
              dropdown-menu dropdown-menu-form-search
              navbar-dropdown-menu-borderless
            "
          >
            <!-- Body -->
            <div class="card-body-height">
              <div class="d-lg-none">
                <div
                  class="input-group input-group-merge navbar-input-group mb-5"
                >
                  <div class="input-group-prepend input-group-text">
                    <i class="bi-search"></i>
                  </div>

                  <input
                    type="search"
                    class="form-control"
                    placeholder="Search in front"
                    aria-label="Search in front"
                  />
                  <a
                    class="input-group-append input-group-text"
                    href="javascript:;"
                  >
                    <i class="bi-x-lg"></i>
                  </a>
                </div>
              </div>

              <span class="dropdown-header">Recent searches</span>

              <div class="dropdown-item bg-transparent text-wrap">
                <a
                  class="btn btn-soft-dark btn-xs rounded-pill"
                  href="index.htm"
                >
                  Gulp <i class="bi-search ms-1"></i>
                </a>
                <a
                  class="btn btn-soft-dark btn-xs rounded-pill"
                  href="index.htm"
                >
                  Notification panel <i class="bi-search ms-1"></i>
                </a>
              </div>

              <div class="dropdown-divider"></div>

              <span class="dropdown-header">Tutorials</span>

              <a class="dropdown-item" href="index.htm">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <span class="icon icon-soft-dark icon-xs icon-circle">
                      <i class="bi-sliders"></i>
                    </span>
                  </div>

                  <div class="flex-grow-1 text-truncate ms-2">
                    <span>How to set up Gulp?</span>
                  </div>
                </div>
              </a>

              <a class="dropdown-item" href="index.htm">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <span class="icon icon-soft-dark icon-xs icon-circle">
                      <i class="bi-paint-bucket"></i>
                    </span>
                  </div>

                  <div class="flex-grow-1 text-truncate ms-2">
                    <span>How to change theme color?</span>
                  </div>
                </div>
              </a>

              <div class="dropdown-divider"></div>

              <span class="dropdown-header">Members</span>

              <a class="dropdown-item" href="index.htm">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <img
                      class="avatar avatar-xs avatar-circle"
                      src="/assets/img/160x160/img10.jpg"
                      alt="Image Description"
                    />
                  </div>
                  <div class="flex-grow-1 text-truncate ms-2">
                    <span
                      >Amanda Harvey
                      <i
                        class="tio-verified text-primary"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Top endorsed"
                      ></i
                    ></span>
                  </div>
                </div>
              </a>

              <a class="dropdown-item" href="index.htm">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <img
                      class="avatar avatar-xs avatar-circle"
                      src="/assets/img/160x160/mr.jpg"
                      alt="Image Description"
                    />
                  </div>
                  <div class="flex-grow-1 text-truncate ms-2">
                    <span>David Harrison</span>
                  </div>
                </div>
              </a>

              <a class="dropdown-item" href="index.htm">
                <div class="d-flex align-items-center">
                  <div class="flex-shrink-0">
                    <div
                      class="avatar avatar-xs avatar-soft-info avatar-circle"
                    >
                      <span class="avatar-initials">A</span>
                    </div>
                  </div>
                  <div class="flex-grow-1 text-truncate ms-2">
                    <span>Anne Richard</span>
                  </div>
                </div>
              </a>
            </div>
            <!-- End Body -->

            <!-- Footer -->
            <a class="card-footer text-center" href="index.htm">
              See all results <i class="bi-chevron-right small"></i>
            </a>
            <!-- End Footer -->
          </div>
          <!-- End Card Search Content -->
        </div>

        <!-- End Search Form -->
      </div>

      <div class="navbar-nav-wrap-content-end">
        <!-- Navbar -->
        <ul class="navbar-nav">
          <li class="nav-item d-none d-sm-inline-block">
            <!-- Notification -->
            <div class="dropdown">
              <button
                type="button"
                class="btn btn-ghost-secondary btn-icon rounded-circle"
                id=""
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
                data-bs-dropdown-animation
              >
                <i class="bi-bell"></i>
                <div
                  class="
                    btn-status btn-sm-status btn-status-danger
                    spinner-grow
                    text-warning
                    spinner-grow-sm
                  "
                  v-if="trrigerEvent"
                  style="margin-left: -21px"
                  role="status"
                ></div>
              </button>
            </div>
            <!-- End Notification -->
          </li>

          <li class="nav-item d-none d-sm-inline-block">
            <!-- Notification -->
            <div class="dropdown">
              <button
                type="button"
                class="btn btn-ghost-secondary btn-icon rounded-circle"
                id=""
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
                data-bs-dropdown-animation
              >
                <i class="bi-bell"></i>
                <div
                  class="
                    btn-status btn-sm-status btn-status-danger
                    spinner-grow
                    text-danger
                    spinner-grow-sm
                  "
                  v-if="trrigerAlert"
                  style="margin-left: -21px"
                  role="status"
                ></div>
              </button>
            </div>
            <!-- End Notification -->
          </li>

          <li class="nav-item">
            <!-- Account -->
            <div class="dropdown">
              <a
                class="navbar-dropdown-account-wrapper"
                href="javascript:;"
                id="accountNavbarDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
                data-bs-dropdown-animation
              >
                <div class="avatar avatar-sm avatar-circle">
                  <img
                    class="avatar-img"
                    src="/assets/img/160x160/img6.jpg"
                    alt="Image Description"
                  />
                  <span
                    class="avatar-status avatar-sm-status avatar-status-success"
                  ></span>
                </div>
              </a>

              <div
                class="
                  dropdown-menu dropdown-menu-end
                  navbar-dropdown-menu
                  navbar-dropdown-menu-borderless
                  navbar-dropdown-account
                "
                aria-labelledby="accountNavbarDropdown"
                style="width: 16rem"
              >
                <div class="dropdown-item-text">
                  <div class="d-flex align-items-center">
                    <div class="avatar avatar-sm avatar-circle">
                      <img
                        class="avatar-img"
                        src="/assets/img/160x160/img6.jpg"
                        alt="Image Description"
                      />
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h5 class="mb-0"></h5>
                      <p class="card-text text-body"></p>
                    </div>
                  </div>
                </div>

                <div class="dropdown-divider"></div>

                <router-link
                  class="dropdown-item"
                  :to="{ name: 'PanelManager-Logout' }"
                  >{{ $t("sign_out") }}</router-link
                >
              </div>
            </div>
            <!-- End Account -->
          </li>
        </ul>
        <!-- End Navbar -->
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";

import jmoment from "jalali-moment";

import { toastSuccess, toastWarning, toastError } from "@/utils/toast";

export default {
  props: ["modelValue", "showMenu"],
  emits: ["update:modelValue"],
  data() {
    return {
      alerts: [],
      alertsEdit: [],
      events: [],
      eventsEdit: [],
      trrigerEvent: false,
      trrigerAlert: false,
      nowDataTime: "",
      serverBaseUrl: axios.defaults.baseURL,
    };
  },
  computed: {
    componentValue: {
      get() {
        return this.modelValue;
      },
      set(componentValue) {
        this.$emit("update:modelValue", componentValue);
      },
    },
  },

  created() {
    // setInterval(this.getNow, 1000);

    const socket = io.connect(this.serverBaseUrl, {
      reconnect: true,
    });
    socket.on("server-das-alert", (data) => {
      this.trrigerAlert = true;
      //document.getElementById("alert").play();
      document.getElementById("event").play();
      /**
        this.alerts.push(data);
        this.alertsEdit = [];
        this.alertsEdit = this.alerts.reverse();
        this.alertsEdit.map((item) => {
          item.datetime = jmoment(item.datetime)
            .locale("fa")
            .format("D MMM YYYY ساعت HH:mm:ss");
        });


       */
    });

    socket.on("server-das-event", (data) => {
      this.trrigerEvent = true;
      //document.getElementById("event").play();
      /**
      this.events.push(data);
      this.eventsEdit = [];
      this.eventsEdit = this.events.reverse();
      this.eventsEdit.map((item) => {
        item.datetime = jmoment(item.datetime)
          .locale("fa")
          .format("D MMM YYYY ساعت HH:mm:ss");
      });
       */
    });
  },

  mounted() {},

  methods: {
    getNow() {
      /*
      const a = jmoment(item.createdAt)
        .locale("fa")
        .format("D MMM YYYY ساعت HH:mm:ss");
      console.log(2);
    */

      const date = new Date();

      this.nowDataTime = jmoment(date)
        .locale("fa")
        .format("D MMM YYYY ساعت HH:mm:ss");
    },

    change() {
      if (this.componentValue) {
        this.componentValue = false;
      } else {
        this.componentValue = true;
      }
    },
  },
};
</script>
<style>
.blink {
  animation: blink-animation 0.5s steps(2, start) infinite;
  -webkit-animation: blink-animation 0.5s steps(2, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
</style>
export const routes = (parentName) => {
  return [
    {
      path: 'devices',
      component: () => import('./Index.vue'),
      children: [
        {
          path: '',
          name: `${parentName}-Devices`,
          component: () => import('./List.vue'),
          props: (route) => ({
            step: route.query.step,
          }),
        },
        {
          path: 'new',
          name: `${parentName}-Devices-New`,
          component: () => import('./Upsert.vue'),
        },
        {
          path: ':id',
          name: parentName + '-' + 'Devices-View',
          component: () => import('./View.vue'),
        },
        {
          path: ':id/edit',
          name: parentName + '-' + 'Devices-Edit',
          component: () => import('./Upsert.vue'),
        },
      ],
    },
  ];
};

export const routes = (parentName) => {
  return [
    {
      path: 'monitoring',
      name: `${parentName}-Monitoring`,
      component: () => import('./Index.vue'),
      children: [
        {
          path: ':id',
          name: `${parentName}-Monitoring-Device`,
          component: () => import('./device/Index.vue'),
          children: [
            {
              path: 'chart/line',
              name: `${parentName}-Monitoring-Chart-Line`,
              component: () => import('./device/Line.vue'),
            },
            {
              path: 'chart/heat',
              name: `${parentName}-Monitoring-Chart-Heat`,
              component: () => import('./device/Heat.vue'),
            },
            {
              path: 'sensors',
              name: `${parentName}-Monitoring-Sensors`,
              component: () => import('./device/Sensors.vue'),
            },

            {
              path: 'system-information',
              name: `${parentName}-Monitoring-System-Information`,
              component: () => import('./device/SystemInformation.vue'),
            },
            {
              path: 'events',
              name: `${parentName}-Monitoring-Events`,
              component: () => import('./device/Events.vue'),
            },

            {
              path: 'alerts',
              name: `${parentName}-Monitoring-Alerts`,
              component: () => import('./device/Alerts.vue'),
            },

            {
              path: 'zones',
              name: `${parentName}-Monitoring-Zones`,
              component: () => import('./device/Zones.vue'),
            },
            {
              path: 'live',
              name: `${parentName}-Monitoring-Live`,
              component: () => import('./device/Live.vue'),
            },
          ],
        },
      ],
    },
  ];
};
